import { ApiBase } from "../api/ApiBase";
import ModelBase from "../models/ModelBase";

export function getUrlQueryString(data = {}) {
    const params = new URLSearchParams(data);
    return params.toString();
};

export function getUrlQueryObject() {
    return Object.fromEntries(new URLSearchParams(window.location.search));
}

export function hash(obj: any) {
    const str = JSON.stringify(obj);
    var hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }

    return hash;
}

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stringToNumber(str: string) {
    if (!str) {
        return;
    }

    return parseInt(str.toString().replace(/\D/g, ''))
}

export async function hydrateUsersWithIpInfo(users: any[]) {
    try {
        const api = new ApiBase(ModelBase, '/ipinfo');
        const ipsInfo = await api.getAllOrDefault({
            ips: users.map(u => u && u.meta && u.meta.ip).join(',')
        });

        return users.map(u => {
            u.ipInfo = ipsInfo.find((info: any) => info.ip === u.meta.ip);

            return u;
        });
    } catch (e) {
        return users;
    }
}
