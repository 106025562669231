import ModelBase from "../models/ModelBase";
import { ApiBase } from "./ApiBase";

export class MeApi extends ApiBase<ModelBase> {
    constructor() {
        super(ModelBase, '');
    }

    async me() {
        const response = await this.get('/me');
        return await response.json();
    }
}
