import './AppLayout.css';

import { Component, ReactNode } from "react";
import { Layout, Menu } from "antd";
import { Link, Outlet } from "react-router-dom";

import { MeApi } from './../../api/MeApi'
import { withRouter } from '../../utils/withRouter';

const { Header, Content, Footer } = Layout;

class AppLayout extends Component {
    meApi = new MeApi();
    state: Readonly<any> = {
        user: {},
    };

    async componentDidMount() {
        const res = await this.meApi.me();
        if (!res || res.success === false) {
            window.location.href = '/login';
        }

        this.setState({
            user: res,
        });
    }

    render(): ReactNode {
        return (
            <>
                <Layout className="layout-container">
                    <Header>
                        <Link to='/'>
                            <div className="brand-name" >
                                <img src="/img/logo-brand.png" alt="Strangers Chat" />
                                Strangers Chat
                            </div>
                        </Link>
                        {/* <Menu>
                            <Menu.Item>
                                <Link to='users'>Play</Link>
                            </Menu.Item>

                            <Menu.Item>
                                <Link to='users'>Users</Link>
                            </Menu.Item>
                        </Menu> */}

                        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['users']} selectedKeys={(this.props as any).history?.pathname}>
                            {/* <Menu.Item key="home">
                                <Link to='/'>
                                    Home
                                </Link>
                            </Menu.Item> */}
                            <Menu.Item key="/users">
                                <Link to='users'>
                                    Users
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/play">
                                <Link to='play'>
                                    Play
                                </Link>
                            </Menu.Item>
                            <Menu.Item className="no-menu" disabled={true} key="">{this.state.user?.name}</Menu.Item>
                        </Menu>
                    </Header>
                    <Content>
                        <Outlet />
                    </Content>
                    <Footer className="text-center">
                        strangers-chat.com ©{new Date().getFullYear()}
                    </Footer>
                </Layout>
            </>
        )
    }
}

export default withRouter(AppLayout);
