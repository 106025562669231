export let HostUrl = 'https://www.strangers-chat.com';
export let SocketHostUrl = `${HostUrl}/admin`;

export const ErrorCodes = Object.freeze({
    ErrorMessage: 1000,

    AppUpdateRequired: 1001, // 'Please update your app.'
    InvalidUserName: 1002, // 'Invalid username.'
    InvalidUser: 1003, // 'Invalid User register again.'

    Blocked: 2001, // 'You can\'t send a message for sometime, you are blocked!'
    BlockedImage: 2002, // 'You can\'t send image, sending images are blocked for few hours!'
});

export const SocketEvents = Object.freeze({
    Connect: 'connect',
    Reconnect: 'reconnect',
    StrangersCount: 'strangers-count',
    OnlineCount: 'onlinecount',
    Message: 'message',
    Image: 'image',
    Typing: 'typing',
    Disconnect: 'disconnect',
    AnError: 'an-error',

    // room specific
    GetUserName: 'get-user-name',
    User: 'user',
    JoinRoom: 'joinroom',
    LeaveRoom: 'leaveroom',
    RoomError: 'room-error',
    UpdateRoomData: 'update-room-data',
    UpdateRoomUsers: 'update-room-users',
    OldMessages: 'old-messages',
    ImageBlocked: 'image-blocked',
    Blocked: 'blocked',
    BlockUser: 'block-user',
    UnblockUser: 'unblock-user',
    ReportUser: 'report-user',

    // strangers connect
    FindStranger: 'find-stranger',
    StrangerLeft: 'stranger-left',

    // admin events
    Auth: 'auth',
    GetBadWords: 'get-bad-words',
    SetBadWords: 'set-bad-words',
    RemoveBadWords: 'remove-bad-words',
    Unblock: 'unblock',
    Block: 'block',
    BlockIp: 'block-ip',
    UnblockIp: 'unblock-ip',
    GetBlockedIps: 'get-blocked-ips',
    RoomMessage: 'room-msg',
});
