import { Alert, Button, Card, Form, Input } from "antd";
import { Component, ReactNode } from "react";
import { LoginApi } from './../../api/LoginApi';

import './Login.css';

const FormItem = Form.Item;

interface ILoginState {
    name: string;
    password: string;
    errorMessage?: string;
}

export default class Login extends Component<any, ILoginState> {
    loginApi = new LoginApi();
    state: Readonly<ILoginState> = {
        name: '',
        password: '',
    };

    async onSubmit() {
        var res = await this.loginApi.login(
            this.state.name,
            this.state.password
        );

        if (res.success === false) {
            this.setState({
                errorMessage: res.message || 'Something is wrong!'
            });
        }
    }

    render(): ReactNode {
        return (
            <div className="login-container">
                <Card className="login-form">
                    <Form onSubmitCapture={e => this.onSubmit()} >
                        <h1>Login</h1>
                        <FormItem>
                            <Input placeholder="Username" name="name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                        </FormItem>
                        <FormItem>
                            <Input placeholder="Password" type='password' name="password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                        </FormItem>

                        <FormItem>
                            <Button type="primary" htmlType="submit">Login</Button>
                        </FormItem>

                        {this.state.errorMessage && (<Alert message={this.state.errorMessage} type="warning" />)}
                    </Form>
                </Card>
            </div>
        )
    }
}