import { HostUrl } from "../Const";
import ModelBase from "../models/ModelBase";
import { getUrlQueryString } from "../utils";

export class ApiBase<T extends ModelBase> {
    protected urlPrefix = `${HostUrl}/api`;
    private endpoint = '';

    constructor(private entityType: new () => T, endpoint: string) {
        this.endpoint = endpoint;
    }

    async getAllOrDefault(query = {}): Promise<Array<T> | any> {
        let url = this.endpoint;
        const queryString = getUrlQueryString(query);
        if (queryString) {
            url += '?' + queryString;
        }

        const response = await this.get(url);
        return await response.json() || {};
    }

    async getOneById(id: string): Promise<T> {
        const response = await this.get(`${this.endpoint}/${id}`);
        return Object.assign(new this.entityType(), await response.json());
    }

    async save(entity: T): Promise<T | undefined> {
        let response;
        if (entity._id) {
            // update
            response = await this.put(`${this.endpoint}/${entity._id}`, entity);
        } else {
            // add
            response = await this.post(this.endpoint, entity);
        }

        if (response && response.status === 200) {
            return Object.assign(new this.entityType(), await response.json());
        }
    }

    async deleteOne(entity: T): Promise<T | undefined> {
        if (!entity?._id) {
            return;
        }

        const response = await this.deleteRequest(`${this.endpoint}/${entity._id}`, entity);
        return Object.assign(new this.entityType(), await response.json());
    }

    protected headers() {
        const requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/json');
        const token = localStorage.getItem('token');
        if (token) {
            requestHeaders.set('x-auth-token', token);
        }

        return requestHeaders;
    }

    protected async get(url: string) {
        return await fetch(`${this.urlPrefix}${url}`, {
            headers: this.headers()
        });
    }

    protected async post(url: string, jsonBody: any) {
        return await fetch(`${this.urlPrefix}${url}`, {
            body: JSON.stringify(jsonBody),
            method: 'POST',
            headers: this.headers()
        });
    }

    protected async put(url: string, jsonBody: any) {
        return await fetch(`${this.urlPrefix}${url}`, {
            body: JSON.stringify(jsonBody),
            method: 'PUT',
            headers: this.headers()
        });
    }

    protected async deleteRequest(url: string, jsonBody: any) {
        return await fetch(`${this.urlPrefix}${url}`, {
            body: JSON.stringify(jsonBody),
            method: 'DELETE',
            headers: this.headers()
        });
    }
}
