import ModelBase from "../models/ModelBase";
import { ApiBase } from "./ApiBase";

export class LoginApi extends ApiBase<ModelBase> {
    constructor() {
        super(ModelBase, '');
    }

    async login(name: string, password: string) {
        const response = await this.post('/login', {
            name,
            password
        });

        const data = await response.json();
        if (data && data.success && data.authToken) {
            localStorage.setItem('token', data.authToken);
            window.location.href = '/';
        }

        return data;
    }
}
