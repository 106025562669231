import './App.css';

import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import AppLayout from './pages/_Layout/AppLayout';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';

const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const Users = React.lazy(() => import("./pages/Users/Users"));
const Play = React.lazy(() => import("./pages/Play/Play"));

export default function App() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<AppLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<>...</>}>
              <Dashboard />
            </Suspense>
          }
        />

        <Route
          path="users"
          element={
            <Suspense fallback={<>...</>}>
              <Users />
            </Suspense>
          }
        />

        <Route
          path="play"
          element={
            <Suspense fallback={<>...</>}>
              <Play />
            </Suspense>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Route>

      {/* <Route
            path="dashboard/*"
            element={
              <React.Suspense fallback={<>...</>}>
                <Dashboard />
              </React.Suspense>
            }
          /> */}
    </Routes>
  );
}
