import { Location, Params, URLSearchParamsInit, useLocation, useParams, useSearchParams } from 'react-router-dom';

export interface IWithRouterProps {
    history: Location;
    params: Readonly<Params<string>>;
    setSearchParams: (nextInit: URLSearchParamsInit, navigateOptions?: {
        replace?: boolean | undefined;
        state?: any;
    } | undefined) => void;
    searchParams: URLSearchParams,
}

export const withRouter = (Component: any) => {
    const Wrapper = (props: any) => {
        const history = useLocation();
        const params = useParams();

        let [searchParams, setSearchParams] = useSearchParams();

        return (
            <Component
                history={history}
                params={params}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
                {...props}
            />
        );
    };

    return Wrapper;
};
